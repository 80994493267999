/* @format */
import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { getLogger } from "@mm/log";

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { logger } from "redux-logger";
import { Provider } from "react-redux";

import gruppen from "@apeiros/react-common/reducers/gruppen";
import personen from "@apeiros/react-common/reducers/personen";
import labels from "@apeiros/react-common/reducers/labels";
import forms from "@apeiros/react-common/reducers/forms";
import usersettings from "@apeiros/react-common/reducers/usersettings";
import settings from "@apeiros/react-common/reducers/settings";
import events from "@apeiros/react-common/reducers/events";

import useLoginContext from "@sa/react-login/useLoginContext";

import {
    registerPersonController,
    registerLabelController,
    registerGruppenController,
    registerEventController,
    registerFormController,
    registerSettingsController
} from "@apeiros/react-common/ctrl";

import { loadPersonen, loadGruppen } from "@apeiros/react-common/actions";

const LOGGER = getLogger("edu/core/ReduxProvider");

function _initStoreWithLoginContext(loginContext) {
    LOGGER.info({ loginContext }, "I do a new Store init from");
    const middlewares = [thunk];

    //if (process.env.NODE_ENV === "development") {
    middlewares.push(logger);
    //}
    const store = createStore(
        combineReducers({
            gruppen,
            personen,
            events,
            labels,
            forms,
            usersettings,
            settings
        }),
        applyMiddleware(...middlewares)
    );

    if (loginContext && !loginContext.isAnonymous()) {
        LOGGER.trace("Register new Controllers");
        registerPersonController(loginContext.getService("personen"));
        registerLabelController(loginContext.getService("labels"));
        registerGruppenController(loginContext.getService("gruppen"));
        registerEventController(loginContext.getService("events"));
        registerFormController(loginContext.getService("forms"));
        registerSettingsController(loginContext.getService("settings"));

        LOGGER.trace("Dispatch minimal init");
        Promise.all([
            store.dispatch(loadPersonen()),
            store.dispatch(loadGruppen())
        ]).then(() => {
            LOGGER.trace("Done minimal init");
        });
    }

    return store;
}

export default function ReduxProvider({ children }) {
    const loginContext = useLoginContext();
    const store = useMemo(() => _initStoreWithLoginContext(loginContext), [
        loginContext
    ]);

    return <Provider store={store}>{children}</Provider>;
}

ReduxProvider.propTypes = {
    children: PropTypes.element.isRequired
};
