/* @format */
import React from "react";
import PropTypes from "prop-types";

import LoginContextProvider from "@sa/react-login/LoginContextProvider";

import BrowserBasedLoginService from "../backend/BrowserBasedLoginService";
const loginService = new BrowserBasedLoginService();

import ReduxProvider from "./ReduxProvider";

export function wrapWithLoginContextProvider({ element }) {
    return (
        <LoginContextProvider loginService={loginService}>
            <ReduxProvider>{element}</ReduxProvider>
        </LoginContextProvider>
    );
}

wrapWithLoginContextProvider.propTypes = {
    element: PropTypes.element.isRequired
};
