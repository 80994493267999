/* @format */
import MockLoginService from "@sa/backend-mock/lib/MockLoginService";
import MockLoginContext from "@sa/backend-mock/lib/MockLoginContext";

import TESTDATA from "./testdata.json";

/** TESTING ONLY */
import bootstrapTestDb from "@sa/backend-mock/lib/helpers/bootstrapTestDb";
bootstrapTestDb(null, null, TESTDATA).then(() => {
    console.log("Done bootrsapping");
});
/** TESTING ONLY */

const STORAGE_KEY = "EDUUSER";

export default class BrowserBasedLoginService extends MockLoginService {
    async init() {
        const strdata = window.sessionStorage.getItem(STORAGE_KEY);
        console.log("Session data is", strdata);
        if (strdata) {
            const { user, dbname } = JSON.parse(strdata);
            console.log("Createing context from Session, data", user, dbname);
            return new MockLoginContext(user, dbname);
        } else {
            return super.init();
        }
    }

    async login(creds) {
        const result = await super.login(creds);
        console.log("before Saving context");
        if (result && !result.isAnonymous()) {
            const { login, roles, dbname } = result;
            const str = JSON.stringify({
                user: { login, roles, dbname },
                dbname
            });
            console.log("Saving context", str);
            window.sessionStorage.setItem(STORAGE_KEY, str);
        } else {
            window.sessionStorage.removeItem(STORAGE_KEY);
        }
        return result;
    }

    async logout() {
        console.log("Delete session storage");
        window.sessionStorage.removeItem(STORAGE_KEY);
        return super.getAnonymousLoginContext();
    }
}
//npm i @sa/backend-mock@1.0.1-pr10-49.0
