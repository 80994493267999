// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aufgaben-index-js": () => import("./../src/pages/aufgaben/index.js" /* webpackChunkName: "component---src-pages-aufgaben-index-js" */),
  "component---src-pages-beratung-index-js": () => import("./../src/pages/beratung/index.js" /* webpackChunkName: "component---src-pages-beratung-index-js" */),
  "component---src-pages-dokumente-index-js": () => import("./../src/pages/dokumente/index.js" /* webpackChunkName: "component---src-pages-dokumente-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klassen-index-js": () => import("./../src/pages/klassen/index.js" /* webpackChunkName: "component---src-pages-klassen-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-spiele-index-js": () => import("./../src/pages/spiele/index.js" /* webpackChunkName: "component---src-pages-spiele-index-js" */),
  "component---src-pages-tagesplan-index-js": () => import("./../src/pages/tagesplan/index.js" /* webpackChunkName: "component---src-pages-tagesplan-index-js" */),
  "component---src-pages-user-index-js": () => import("./../src/pages/user/index.js" /* webpackChunkName: "component---src-pages-user-index-js" */)
}

