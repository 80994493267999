/* @format */
import { createMuiTheme } from "@material-ui/core";

import purple from "@material-ui/core/colors/purple";

const theme = createMuiTheme({
    palette: {
        primary: purple,
        secondary: {
            main: "#f44336"
        }
    }
});

export default theme;
